/**
 * @generated SignedSource<<c0bf2a1988d706ccd3b0221a20f6f9e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlogPostItem_item$data = {
  readonly title: string;
  readonly imageUrl: string | null;
  readonly excerpt: string;
  readonly date: string;
  readonly slug: string;
  readonly " $fragmentType": "BlogPostItem_item";
};
export type BlogPostItem_item$key = {
  readonly " $data"?: BlogPostItem_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlogPostItem_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlogPostItem_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "excerpt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "BlogPost",
  "abstractKey": null
};

(node as any).hash = "6bcab581071c2ca79d30f87057149968";

export default node;
