import clsx from 'clsx';
import React, { ReactNode, ReactElement, ElementType } from 'react';
import classes from './Card.module.scss';
import A from '../A/A';

export type Props<T extends ElementType = 'div'> = {
  className?: string;
  component?: T;
  interactive?: boolean;
  children: ReactNode;
  ref: React.ForwardedRef<ElementType>;
} & React.ComponentProps<T>;

function Card<T extends ElementType = 'div'>(
  { className, children, component, interactive, ...rest }: Props<T>,
  ref: React.ForwardedRef<ElementType>,
): ReactElement {
  const Root = (component || 'div') as React.ElementType;
  return (
    <Root
      className={clsx(
        classes.root,
        interactive || component === A ? classes.interactive : classes.outlined,
        className,
      )}
      {...rest}
      ref={ref}
    >
      {children}
    </Root>
  );
}
export default React.forwardRef(Card);
