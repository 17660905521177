import React from 'react';

interface Props {
  color?: string;
  className?: string;
}

const ChevronIcon: React.FC<Props> = ({ color, className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13"
    viewBox="0 0 12 22"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color || 'currentColor' || '#1C2451'}
      strokeWidth="2"
      transform="rotate(-180 5.5 10.5)"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="9.864 10 .197 0" />
      <path d="M9.86363636,10 L0.196969697,20" />
    </g>
  </svg>
);

export default ChevronIcon;
