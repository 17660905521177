import React from 'react';
import { graphql } from 'react-relay';
import { useFragment } from 'react-relay/hooks';
import dayjs from 'dayjs';
import ChevronIcon from '../../assets/ChevronIcon';
import Button from '../../atoms/Button/Button';
import { BlogPostItem_item$key } from './__generated__/BlogPostItem_item.graphql';
import A from '../../atoms/A/A';
import Card from '../../atoms/Card/Card';
import Image from 'components/atoms/Image/Image';
import classes from './BlogPostItem.module.scss';
export interface Props {
  postRef: BlogPostItem_item$key;
}

const fragmentSpec = graphql`
  fragment BlogPostItem_item on BlogPost {
    title
    imageUrl
    excerpt
    date
    slug
  }
`;

const BlogPostItem: React.FC<Props> = React.forwardRef<HTMLLIElement, Props>(function BlogPostItem(
  { postRef },
  ref,
) {
  const post = useFragment(fragmentSpec, postRef);

  return (
    <li ref={ref}>
      <Card interactive component={A} to={`/blog/${post.slug}`} className={classes.root}>
        {post.imageUrl && (
          <Image loading="lazy" src={post.imageUrl} alt="" width={352} height={200} />
        )}
        <article>
          <h3>{post.title}</h3>
          <span>{dayjs(post.date).calendar()}</span>
          <p>{post.excerpt}</p>
          <Button buttonType="tertiary">
            Read more
            <ChevronIcon />
          </Button>
        </article>
      </Card>
    </li>
  );
});

export default BlogPostItem;
