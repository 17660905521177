import React from 'react';
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import { BlogPostsQuery } from './__generated__/BlogPostsQuery.graphql';
import ItemList from '../../molecules/ItemList/ItemList';
import ErrorPage from '../ErrorPage/ErrorPage';
import BlogPostItem from '../../molecules/BlogPostItem/BlogPostItem';
import { BlogPosts_posts$key } from './__generated__/BlogPosts_posts.graphql';
import Button from '../../atoms/Button/Button';
import AsideEntryForm from '../../organisms/AsideEntryForm/AsideEntryForm';
import Txt from '../../atoms/Txt/Txt';
import styles from './BlogPosts.module.scss';
import Head from 'next/head';
import { useRouter } from 'next/router';

export interface Props {}

export const BLOG_POST_COUNT = 12;

export const blogPostsQuery = graphql`
  query BlogPostsQuery($count: Int) {
    ...BlogPosts_posts @arguments(count: $count)
  }
`;

const blogPostsFragment = graphql`
  fragment BlogPosts_posts on Query
  @argumentDefinitions(count: { type: "Int", defaultValue: 12 }, cursor: { type: "String" })
  @refetchable(queryName: "BlogPostsPaginationQuery") {
    blogPosts(first: $count, after: $cursor) @connection(key: "BlogPosts_query_blogPosts") {
      edges {
        cursor
        node {
          id
          ...BlogPostItem_item
        }
      }
    }
  }
`;

const BlogPostsList: React.FC<{ postsRef: BlogPosts_posts$key }> = ({ postsRef }) => {
  const pagination = usePaginationFragment(blogPostsFragment, postsRef);
  const { data, loadNext, isLoadingNext, hasNext } = pagination;
  const router = useRouter();

  return data.blogPosts ? (
    <div>
      <Head>
        <link
          rel="alternate"
          type="application/rss+xml"
          title="Qmee Blog RSS Feed"
          href={`https://qmee.com/rss/blog-rss/feed-${router.locale}.xml`}
        />
      </Head>
      <AsideEntryForm>
        <div className={styles.background} />
        <Txt component="h1" className={styles.header}>
          Latest from our blog
        </Txt>
        <ItemList animate>
          {data.blogPosts.edges.map(({ node: post }) => (
            <BlogPostItem key={post.id} postRef={post} />
          ))}
        </ItemList>
        {hasNext ? (
          <Button
            className={styles.showMoreButton}
            disabled={isLoadingNext}
            buttonType="tertiary"
            onClick={() => loadNext(12)}
          >
            <Txt component="p">Show more</Txt>
          </Button>
        ) : null}
      </AsideEntryForm>
    </div>
  ) : (
    <ErrorPage errorDescription="We are having trouble loading our blog at the moment." />
  );
};

const BlogPosts: React.FC<Props> = () => {
  const data = useLazyLoadQuery<BlogPostsQuery>(
    blogPostsQuery,
    {
      count: BLOG_POST_COUNT,
    },
    {
      fetchPolicy: 'store-or-network',
    },
  );
  return <BlogPostsList postsRef={data} />;
};

export default BlogPosts;
