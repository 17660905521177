import BlogPosts, { blogPostsQuery } from 'components/pages/BlogPosts/BlogPosts';
import { buildPage, buildQueryPreloader } from 'utils/pageHelper';

export default buildPage(BlogPosts, {
  metaData: {
    title: 'Blog',
    description: 'Come and check out the Qmee blog and find out more about us.',
  },
});

export const getStaticProps = buildQueryPreloader(blogPostsQuery);
