import React from 'react';
import clsx from 'clsx';
import { useIsLoggedIn } from '../../templates/AuthenticationProvider/AuthenticationProvider';
import EntryForm from '../EntryFormOld/EntryForm';
import Card from '../../atoms/Card/Card';
import classes from './AsideEntryForm.module.scss';
export interface Props {
  className?: string;
  contentClassName?: string;
  children: React.ReactNode;
}

const AsideEntryForm: React.FC<Props> = ({ children, className, contentClassName }) => {
  return (
    <div className={clsx(classes.root, className)}>
      <div className={clsx(classes.contentContainer, contentClassName)}>{children}</div>
      <Card
        className={clsx(classes.entryContainer, 'logged-out-only')}
        component="aside"
        interactive
      >
        <EntryForm />
      </Card>
    </div>
  );
};
export default AsideEntryForm;
