import React from 'react';
import clsx from 'clsx';
import FlipMove from 'react-flip-move';
import styles from './ItemList.module.scss';

export interface Props {
  className?: string;
  animate?: boolean;
  children: React.ReactNode;
}

export const ListItemWrapper = React.forwardRef<HTMLLIElement, { children: React.ReactNode }>(
  function ListItemWrapper({ children }, ref) {
    return <li ref={ref}>{children}</li>;
  },
);

const PatchedFlipMove = FlipMove as unknown as React.FC<
  FlipMove.FlipMoveProps & { children: React.ReactNode }
>;
const ItemList: React.FC<Props> = ({ className, children, animate }) => {
  if (animate) {
    return (
      <PatchedFlipMove typeName="ul" className={clsx(styles.root, className)}>
        {children}
      </PatchedFlipMove>
    );
  }
  return <ul className={clsx(styles.root, className)}>{children}</ul>;
};

export default ItemList;
